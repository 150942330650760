


















import Vue from 'vue';
import MainCard from './MainCard.vue'
import Card from './Card.vue'

export default Vue.extend({
  name: 'CardFeed',
  components: {
    MainCard,
    // Card,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      data: `it works!`,
      learn_more_clicked: false,
    };
  },
  methods: {
    onLearnMoreClickCallback(): void {
      this.learn_more_clicked = true;
    },
  },
});
