






import Vue from 'vue';
import CardFeed from './components/CardFeed.vue'

export default Vue.extend({
  name: 'App',
  components: {
    CardFeed,
  }
});
