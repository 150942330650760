






import Vue from 'vue';

export default Vue.extend({
  name: 'Card',
  props: {
    msg: String,
  },
  data() {
    return {
      data: `it works!`,
    };
  }
});
