














import Vue from 'vue';
import Icons from './Icons.vue'
import Card from './Card.vue'

export default Vue.extend({
  name: 'MainCard',
  components: {
    Icons,
    Card,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      // img_url: `https://picsum.photos/200`,
      title: `adriene cuenco`,
      subtitle: `frontend dev`,
      learn_more_clicked: false,
    };
  },
  methods: {
    onClick() {
      this.learn_more_clicked = true;
      this.$emit('learnMoreClicked')
    },
  },
});
