









import Vue from 'vue';

export default Vue.extend({
  name: 'Icons',
  props: {
  },
  data() {
    return {
      github_link: `https://github.com/acuenco`,
      linkedin_link: `https://www.linkedin.com/in/acuenco/`,
      instagram_link: `https://www.instagram.com/adrieneCodes/`,
    };
  }
});
